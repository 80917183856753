import { FirebaseOptions } from '@angular/fire/app';

const firebaseOptions: FirebaseOptions = {
  apiKey: 'AIzaSyC0vJLrMDTl0LjJbbfePSF-do4MDvCNdEs',
  authDomain: 'itsmycv-273608.firebaseapp.com',
  databaseURL:
    'https://itsmycv-273608-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'itsmycv-273608',
  appId: '1:866704765368:web:8d5af35c77cab142b29f4d',
  measurementId: 'G-WXBDRS8011',
};

export const environment = {
  production: true,
  api: 'https://apis.itsmycv.com/api',
  candidates_api: 'https://apis.itsmycv.com/api',
  linkedin_scraper_api: 'https://apis.itsmycv.com/api/v1',
  pdf_printer_api: 'https://pdfmaker.itsmycv.com',
  teams_api: 'https://apis.itsmycv.com',
  auth_api: 'https://apis.itsmycv.com',
  metadata_api: 'https://apis.itsmycv.com',
  notification_api: 'https://apis.itsmycv.com',
  mycv_api: 'https://apis.itsmycv.com',
  avatar_base_url: 'https://users-avatar.itsmycv.com',
  version_timestamp: '20240706062430',
  firebase: firebaseOptions,
  sentry: {
    dsn: 'https://72ae0c8fef5057efd6c97454ac57273a@o4507293337976832.ingest.de.sentry.io/4507294028595281',
    tracePropagationTargets: ['candidates.itsmycv.com'],
  },
};
